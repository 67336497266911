import React from 'react';
import { theme } from '../Theme';
import { styled, StyledProps } from '@glitz/react';
import { SetQuantity } from '../Cart/Cart';
import QuantityDropDown from '../Shared/Quantity/QuantityDropDown';

type PropTypes = StyledProps & {
  price: string;
  code: string;
  currentQuantity: number;
  loweredPrice?: number;
  allowEdit?: boolean;
  showQuantity?: boolean;
};

function PriceInfo({
  loweredPrice,
  price,
  code,
  currentQuantity,
  allowEdit,
  showQuantity = true,
  compose,
}: PropTypes) {
  return (
    <Wrapper>
      {allowEdit && showQuantity && (
        <QuantityDropDown
          code={code}
          maxQuantity={50}
          minQuantity={0}
          currentQuantity={currentQuantity}
          // updateQuantity={SetQuantity}
        />
      )}
      {loweredPrice ? (
        <LoweredPrice>
          <PriceText
            css={compose({
              color: loweredPrice ? theme.sale : theme.breadText,
            })}
          >
            {loweredPrice}
          </PriceText>
          <OldPrice>{price}</OldPrice>
        </LoweredPrice>
      ) : (
        <PriceText css={compose()}>{price}</PriceText>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div({
  margin: { bottom: theme.medium },
  display: 'flex',
  flexDirection: 'column',
});

const PriceText = styled.span({
  opacity: 0.9,
  fontSize: '12px',
  fontWeight: 'bold',
});

const LoweredPrice = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

const OldPrice = styled.span({
  opacity: 0.9,
  fontSize: '10px',
  fontWeight: 'bold',
  color: theme.breadText,
  textDecoration: 'line-through',
});

export default styled(PriceInfo);
