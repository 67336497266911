import { ReactChild } from 'react';

type PropTypes = {
  condition: boolean;
  wrapper: Function;
  children: ReactChild;
};

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: PropTypes): JSX.Element => {
  return condition ? wrapper(children) : children;
};

export default ConditionalWrapper;
