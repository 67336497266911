import React, { useState, useRef } from 'react';
import { styled, theme } from '../../Theme';
import { ArrowRightIcon as ArrowIcon } from '../Icons';

type PropTypes = {
  code: string;
  maxQuantity: number;
  minQuantity: number;
  currentQuantity: number;
  updateQuantity?: (productCode: string, amount: number) => void;
};

function QuantityDropDown({
  code,
  currentQuantity,
  updateQuantity,
  maxQuantity,
  minQuantity,
}: PropTypes) {
  const [showQuantity, toggleQuantity] = useState(false);
  const ref = useRef(null);

  function mapQuantity() {
    var array = [];
    for (let i = minQuantity; i <= maxQuantity; i++) {
      array.push(
        <QuantityDropDownButton
          key={i}
          onClick={() => {
            // updateQuantity(code, i);
            toggleQuantity(false);
          }}
          css={{
            opacity: i === currentQuantity ? 0.5 : 1,
          }}
        >
          {i}
        </QuantityDropDownButton>
      );
    }
    return array;
  }

  return (
    <QuantityDropDownContainer>
      <QuantityFlex onClick={() => toggleQuantity(prev => !prev)} ref={ref}>
        <CurrentQuantity>{currentQuantity} </CurrentQuantity>
        <StyledArrow
          css={{ transform: showQuantity ? 'rotate(270deg)' : 'rotate(90deg)' }}
        />
      </QuantityFlex>
      {showQuantity && <DropDown>{mapQuantity()}</DropDown>}
    </QuantityDropDownContainer>
  );
}

//Dropdown
const QuantityDropDownButton = styled.button({
  width: '-webkit-fill-available',
  height: '100%',
  font: { size: theme.alpha },
  textAlign: 'left',
  ':focus': {
    boxShadow: '0 0 0 2px #FFF',
  },
  margin: {
    x: theme.gamma,
    y: theme.alpha,
  },
});

const QuantityDropDownContainer = styled.div({
  marginBottom: theme.delta,
  height: '35px',
  width: '125px',
  display: 'block',
  textAlign: 'left',
  border: {
    xy: { width: 1, color: '#a3a3a3' },
  },
  position: 'relative',
});

const QuantityFlex = styled.div({
  display: 'flex',
  position: 'relative',
  justifyContent: 'space-between',
  height: '100%',
  alignItems: 'center',
  cursor: 'pointer',
});

const DropDown = styled.div({
  height: 'auto',
  maxHeight: '200px',
  display: 'flex',
  flexDirection: 'column',
  border: {
    xy: { width: 1, color: theme.modal },
  },
  backgroundColor: 'white',
  position: 'absolute',
  width: '100%',
  zIndex: 10,
  overflow: 'auto',
});

const CurrentQuantity = styled.span({
  fontSize: theme.alpha,
  lineHeight: 2,
  letterSpacing: 0.7,
  color: theme.primary,
  margin: { left: theme.gamma },
});

const StyledArrow = styled(ArrowIcon, {
  position: 'absolute',
  top: 10,
  right: theme.gamma,
  height: theme.gamma,
  width: theme.medium,
  color: theme.primary,
  transition: {
    duration: theme.timings.oneFifth,
    property: 'all',
  },
});

export default QuantityDropDown;
