import React from 'react';
import { styled, theme } from '../../../Theme';
import { media } from '@glitz/core';
import IKexCart from '../../../Cart/Models/KexCart.interface';
import KexLink from '../../../Shared/KexLink/KexLink';

type PropTypes = {
  cart: IKexCart;
};

function CartPaymentInformation({ cart }: PropTypes) {
  return (
    <Payment>
      <PaymentInformation>
        <BasketTitle>Totalsumma</BasketTitle>
        <Costs>
          <Price>
            <p>Deltotal</p>
            <p>{cart.totalPriceForItems}</p>
          </Price>
          <Price>
            <p>Frakt</p>
            <p>{cart.totalFreightPrice}</p>
          </Price>
        </Costs>
        <Bottom>
          <Price>
            <p>Totalsumma (inkl. moms)</p>
            <p>{cart.totalPrice}</p>
          </Price>
          <CheckoutLink href="/en/checkout">Gå till kassan</CheckoutLink>
        </Bottom>
      </PaymentInformation>
    </Payment>
  );
}

const BasketTitle = styled.h4({
  font: { size: theme.eta },
});

const Payment = styled.div({
  margin: { top: 0 },
  fontWeight: 'lighter',
  color: theme.heading,
  width: '100%',
});

const Price = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  padding: {
    y: '5px',
    x: '0',
  },
  font: {
    weight: theme.fontWeight.bold,
  },
});

const PaymentInformation = styled.div({
  marginTop: 0,
  fontWeight: 'lighter',
  color: theme.heading,
  position: 'sticky',
  padding: {
    xy: theme.huge,
  },
  backgroundColor: theme.white,
  ...media(theme.mediaQuery.mediaMinMedium, {
    position: 'sticky',
    top: '100px',
  }),
});

const Costs = styled.div({
  marginTop: 0,
  color: theme.heading,
  padding: {
    y: '15px',
    x: '0',
  },
  border: {
    bottom: {
      style: 'solid',
      color: 'rgba(72, 72, 72, 0.5)',
      width: theme.tiny,
    },
  },
});

const Bottom = styled.div({
  fontWeight: 'lighter',
  color: `${theme.heading}`,
  padding: {
    y: '15px',
    x: '0',
  },
  display: 'flex',
  flexDirection: 'column',
});

const CheckoutLink = styled(KexLink, {
  fontSize: theme.alpha,
  marginTop: '40px',
  fontWeight: 'bold',
  lineHeight: 1.33,
  letterSpacing: 0.45,
  backgroundColor: theme.primary,
  color: theme.white,
  padding: {
    x: '8px',
    y: '12px',
  },
  textAlign: 'center',
  width: '100%',
});

export default CartPaymentInformation;
