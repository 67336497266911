import React from 'react';
import { styled, theme } from '../Theme';
import ProductItem from '../ProductItem/ProductItem';
import ProductItemMobile from '../ProductItem/ProductItemMobile';
import { pseudo } from '@glitz/core';
import { StyleOrStyleArray } from '@glitz/type';
import IKexVariation from '../KexVariation/Models/VariationModel.interface';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import useMedia from '../Shared/Hooks/useMedia';

type PropTypes = {
  products: IKexVariation[];
  isLoading: boolean;
  allowEdit?: boolean;
  isMiniCart?: boolean;
};

function ProductList({
  products,
  isLoading,
  allowEdit = false,
  isMiniCart,
}: PropTypes) {
  const {
    translations: { 'common/loading': loading },
  } = useAppSettingsData();
  const isMobile = useMedia(theme.mediaQuery.mediaMaxLarge);

  return isLoading ? (
    <p>{loading}</p>
  ) : (
    <styled.Div css={isMiniCart ? minicartStyle : {}}>
      {products &&
        products.map(product =>
          isMobile ? (
            <ProductItemMobile
              key={product.code}
              product={product}
              allowEdit={allowEdit}
            />
          ) : (
            <ProductItem
              key={product.code}
              product={product}
              allowEdit={allowEdit}
            />
          )
        )}
    </styled.Div>
  );
}

const minicartStyle: StyleOrStyleArray = {
  height: '352px',
  overflowY: 'scroll',
  ...pseudo('::scrollbar', {
    display: 'none',
  }),
  ...pseudo('::-webkit-scrollbar', {
    display: 'none',
  }),
};

export default ProductList;
