import React, { ReactElement } from 'react';
import PriceInfo from '../PriceInfo/PriceInfo';
import { pseudo, media } from '@glitz/core';
import IKexVariation from '../KexVariation/Models/VariationModel.interface';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import KexLink from '../Shared/KexLink/KexLink';
import { styled, theme } from '../Theme';
import TrashIcon from '../Shared/Icons/TrashIcon';
import HeartIcon from '../Shared/Icons/HeartIcon';
import { RemoveFromCart } from '../Cart/Cart';
import ConditionalWrapper from '../Shared/Common/ConditionalWrapper';

type PropTypes = {
  product: IKexVariation;
  allowEdit?: boolean;
};

function ProductItem({ product, allowEdit = false }: PropTypes) {
  const {
    translations: {
      'productItem/size': size,
      'productItem/amount': amount,
      'productItem/remove': removeText,
      'productItem/wishlist': wishlistText,
    },
    languageRoute,
  } = useAppSettingsData();
  return (
    <ConditionalWrapper
      condition={!allowEdit}
      wrapper={(children: ReactElement) => (
        <KexLink href={product.to}>{children}</KexLink>
      )}
    >
      <Item>
        <ConditionalWrapper
          condition={allowEdit}
          wrapper={(children: ReactElement) => (
            <KexLink href={product.to}>{children}</KexLink>
          )}
        >
          <ImageContainer>
            {product.image && (
              <Image src={product.image.src} alt={product.image.alt} />
            )}
          </ImageContainer>
        </ConditionalWrapper>
        <Info>
          <Details>
            <ConditionalWrapper
              condition={allowEdit}
              wrapper={(children: ReactElement) => (
                <StyledKexLink href={product.to}>{children}</StyledKexLink>
              )}
            >
              <Title>{product.name}</Title>
            </ConditionalWrapper>
            <DetailText>
              {size}: {product.size}
            </DetailText>
            <DetailText>
              {amount}: {product.quantity}
            </DetailText>
            {allowEdit && (
              <Actions>
                <ActionButton
                  onClick={() => RemoveFromCart(product.code, languageRoute)}
                >
                  <Trash />
                  <ButtonText>{removeText}</ButtonText>
                </ActionButton>
                <ActionButton /*NOT IMPLEMENTED */>
                  <Heart />
                  <ButtonText>{wishlistText}</ButtonText>
                </ActionButton>
              </Actions>
            )}
            {!allowEdit && (
              <PriceInfo
                price={product.totalPrice}
                code={product.code}
                currentQuantity={product.quantity}
                allowEdit={allowEdit}
              />
            )}
          </Details>
          {allowEdit && (
            <PriceInfo
              price={product.totalPrice}
              code={product.code}
              currentQuantity={product.quantity}
              allowEdit={allowEdit}
            />
          )}
        </Info>
      </Item>
    </ConditionalWrapper>
  );
}

const StyledKexLink = styled(KexLink, {
  margin: { bottom: theme.medium },
});

const Item = styled.div({
  display: 'flex',
  position: 'relative',
  padding: { y: theme.gamma },
  ...pseudo(':not(:last-of-type)::after', {
    content: '""',
    position: 'absolute',
    width: '100%',
    margin: {
      x: 'auto',
      y: 0,
    },
    display: 'block',
    backgroundColor: theme.primary,
    height: theme.tiny,
    bottom: 0,
    opacity: 0.5,
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    flexDirection: 'row',
  }),
  flexDirection: 'column',
});

const ImageContainer = styled.div({
  width: theme.massive,
  height: theme.enormous,
});

const Actions = styled.div({
  display: 'flex',
});

const Image = styled.img({
  objectFit: 'cover',
  width: '100%',
  height: '100%',
});

const Info = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'start',
  ...media(theme.mediaQuery.mediaMinMedium, {
    justifyContent: 'space-between',
  }),
});

const Title = styled.span({
  font: { size: theme.beta, weight: theme.fontWeight.bold },
  margin: { bottom: theme.medium },
  letterSpacing: theme.letterSpacing.nearWide,
  color: theme.primary,
});

const Details = styled.div({
  display: 'flex',
  flexDirection: 'column',
  ...media(theme.mediaQuery.mediaMinMedium, {
    margin: { left: theme.gamma },
  }),
  margin: { left: theme.medium },
});

const DetailText = styled.span({
  font: {
    size: theme.alpha,
  },
  color: theme.primary,
  margin: { bottom: theme.small },
  ...pseudo(':last-of-type', {
    margin: { bottom: theme.medium },
  }),
});

const ActionButton = styled.div({
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  ...media(theme.mediaQuery.mediaMinMedium, {
    margin: { right: theme.gamma },
  }),
  margin: { xy: theme.none },
});

const ButtonText = styled.span({
  font: { size: theme.alpha },
  padding: { bottom: theme.tiny },
  ':hover': {
    border: {
      bottom: {
        style: 'solid',
        color: theme.black,
        width: theme.tiny,
      },
    },
  },
});

const Trash = styled(TrashIcon, {
  width: theme.beta,
  height: theme.gamma,
  margin: { right: theme.small },
});

const Heart = styled(HeartIcon, {
  width: theme.gamma,
  height: theme.beta,
  margin: { right: theme.small },
});

export default ProductItem;
