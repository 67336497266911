import React from 'react';
import { styled, theme } from '../Theme';
import { media } from '@glitz/core';
import ProductList from '../ProductList/ProductList';

import CartPaymentInformation from '../Shared/Checkout/Payment/PaymentInformation';
import { GetCart } from './Cart';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import ICartPage from './Models/CartPageModel.interface';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';

function CartPage() {
  const {} = useCurrentPage<ICartPage>();

  const { cart, isLoading } = GetCart();
  const {
    translations: {
      'cartPage/cartHeader': cartHeader,
      'common/loading': loading,
    },
  } = useAppSettingsData();

  return (
    <Main>
      {isLoading && <div>{loading}</div>}
      {!isLoading && cart && (
        <CartContainer>
          <CartContent>
            <CartHeader>{cartHeader}</CartHeader>
            <ProductList
              products={cart.lineItems}
              isLoading={false}
              allowEdit={true}
            />
          </CartContent>
          <CartPaymentInformation cart={cart} />
        </CartContainer>
      )}
    </Main>
  );
}

const CartContainer = styled.div({
  display: 'grid',
  gridGap: theme.gamma,
  gridTemplateColumns: '1fr',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  margin: {
    y: theme.gamma,
    x: 'auto',
  },
  padding: { x: theme.gamma },
  ...media(theme.mediaQuery.mediaMinLarge, {
    gridTemplateColumns: '6.5fr 3.5fr',
    gridGap: theme.great,
    margin: {
      y: theme.massive,
      x: 'auto',
    },
  }),
  maxWidth: theme.contentMaxWidth,
});

const CartContent = styled.div({
  fontWeight: 'lighter',
  color: theme.heading,
  backgroundColor: theme.white,
  padding: {
    xy: theme.huge,
  },
  margin: { top: 0 },
});

const CartHeader = styled.h4({
  margin: { bottom: theme.gamma },
  font: { size: theme.eta, weight: theme.fontWeight.lighter },
});

const Main = styled.div({
  backgroundColor: theme.secondaryText,
});

export default CartPage;
